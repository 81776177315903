import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import { Box, Container, FormHelperText } from "@mui/material";
import { useThemeContext } from "../../context/AppProvider";
import { colors } from "../theme/templateColors";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { useState } from "react";

export default function ContactPage() {
  pageTitle("Contact Us");
  const theme = useThemeContext();
  const [successMessage, setSuccessMessage] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Container
        maxWidth="xxl"
        sx={{ backgroundColor: theme.mode ? colors.gray : colors.white }}
      >
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                color={theme.mode ? "white" : "black"}
                title="BeTCA"
                subtitle="Get in touch with us."
              />
              <Spacing lg="55" md="30" />
              <ContactInfoWidget
                withIcon
                color={theme.mode ? "gray" : "black"}
              />
              <Spacing lg="0" md="50" />
            </Div>
            <Div className="col-lg-6">
              <Formik
                initialValues={{
                  fullName: "",
                  email: "",
                  mobileNo: "",
                  description: "",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  fullName: Yup.string().required(
                    "Please enter your Full Name"
                  ),
                  email: Yup.string()
                    .email("Please enter a valid email")
                    .max(50)
                    .required("Email is required"),
                  mobileNo: Yup.string()
                    .max(10, "Please enter a valid mobile number")
                    .min(10, "Please enter a valid mobile number")
                    .required("Contact Number is required"),
                  description: Yup.string().required(
                    "Please add a description"
                  ),
                })}
                onSubmit={async (
                  values,
                  { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    setStatus({ success: false });
                    setSubmitting(true);

                    // eslint-disable-next-line no-unused-vars
                    const response = await axios.post(
                      "https://betca-api.ajinkyatechnologies.in/api/email/sendemail",
                      values
                    );
                    setStatus({ success: true });
                    setSubmitting(false);
                    setSuccessMessage("Message sent successfully");
                    resetForm("");
                  } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  values,
                  resetForm,
                }) => (
                  <form noValidate onSubmit={handleSubmit} className="row">
                    <Div className="col-sm-12">
                      <label
                        htmlFor="fullName"
                        // className="cs-primary_color"
                        style={{ color: theme.mode ? "white" : "black" }}
                      >
                        Full Name*
                      </label>
                      <input
                        type="text"
                        value={values.fullName}
                        name="fullName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="fullName"
                        error={Boolean(touched.fullName && errors.fullName)}
                        className="cs-form_field"
                        style={{ color: theme.mode ? "white" : "black" }}
                      />
                      {touched.fullName && errors.fullName && (
                        <FormHelperText error id="helper-text-fullName">
                          {errors.fullName}
                        </FormHelperText>
                      )}
                      <Spacing lg="20" md="20" />
                    </Div>
                    <Div className="col-sm-6">
                      <label
                        htmlFor="email"
                        // className="cs-primary_color"
                        style={{ color: theme.mode ? "white" : "black" }}
                      >
                        Email Address*
                      </label>
                      <input
                        type="text"
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="email"
                        error={Boolean(touched.email && errors.emailfullName)}
                        className="cs-form_field"
                        style={{ color: theme.mode ? "white" : "black" }}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id="helper-text-email">
                          {errors.email}
                        </FormHelperText>
                      )}
                      <Spacing lg="20" md="20" />
                    </Div>
                    {/* <Div className="col-sm-6">
                  <label className="cs-primary_color">Project Type*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div> */}
                    <Div className="col-sm-6">
                      <label
                        htmlFor="mobileNo"
                        // className="cs-primary_color"
                        style={{ color: theme.mode ? "white" : "black" }}
                      >
                        Mobile*
                      </label>
                      <input
                        type="number"
                        value={values.mobileNo}
                        name="mobileNo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="mobileNo"
                        error={Boolean(touched.mobileNo && errors.mobileNo)}
                        className="cs-form_field"
                        style={{ color: theme.mode ? "white" : "black" }}
                      />
                      {touched.mobileNo && errors.mobileNo && (
                        <FormHelperText error id="helper-text-mobileNo">
                          {errors.mobileNo}
                        </FormHelperText>
                      )}
                      <Spacing lg="20" md="20" />
                    </Div>
                    <Div className="col-sm-12">
                      <label
                        htmlFor="description"
                        // className="cs-primary_color"
                        style={{ color: theme.mode ? "white" : "black" }}
                      >
                        Description*
                      </label>
                      <textarea
                        cols="30"
                        rows="7"
                        value={values.description}
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="description"
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        className="cs-form_field"
                        style={{ color: theme.mode ? "white" : "black" }}
                      />
                      {touched.description && errors.description && (
                        <FormHelperText error id="helper-text-description">
                          {errors.description}
                        </FormHelperText>
                      )}
                    </Div>
                    <Spacing lg="25" md="25" />
                    {successMessage && (
                      <FormHelperText success id="helper-text-description">
                        {successMessage}
                      </FormHelperText>
                    )}
                    <Spacing lg="25" md="25" />
                    <Div className="col-sm-6">
                      <Box sx={{ m: 0.8 }}>
                        <button className="cs-btn cs-style1">
                          <span>Send Message</span>
                          <Icon icon="bi:arrow-right" />
                        </button>
                      </Box>
                    </Div>
                    <Div className="col-sm-6">
                      <Box sx={{ m: 0.8 }}>
                        <button
                          className="cs-btn cs-style1"
                          onClick={() => resetForm("")}
                        >
                          <span>Clear Form</span>
                        </button>
                      </Box>
                    </Div>
                  </form>
                )}
              </Formik>
            </Div>
          </Div>
        </Div>
        <Spacing lg="80" md="80" />
      </Container>
    </>
  );
}
